import React from "react";
import Header from "../Header/Header";
import Cookie from "../Cookie";
export default function Layout({ children }) {
  return (
    <React.Fragment>
      <Header />
      {children}
      <Cookie />
      {/*   <Footer/> */}
    </React.Fragment>
  );
}
