import React from "react";
import "../assets/css/components/Contact/Contact.css";
import Layout2 from "../components/Layout/Layout2";
import ContactLeft from "../components/ContactLeft/ContactLeft";
import "../assets/css/components/Footer/Footer.css";
import CopyRight from "../components/CopyRight/CopyRight";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { useQuery } from "@apollo/client";
import { GET_CONTACT } from "../apollo/queries";
import Loader from "../components/Loader";

const Contact = () => {
  const { data, error, loading } = useQuery(GET_CONTACT, {
    variables: {
      branch: "Karafaki Meyhane",
    },
  });
  let contact = {};
  if (data) contact = data.getContactById;
  if (error) console.log(error);
  return (
    <Layout2>
      {loading && <Loader />}
      <main className="contact">
        <div className="container mt-5">
          <div className="row m-0">
            <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
              <div className="contact_right">
                <h3>Telefon:</h3>
                <div className="mb-3">
                  <span className="contact_span">
                    <a href={`tel:${contact?.telOne}`}>{contact?.telOne}</a>
                  </span>
                </div>
                <span className="contact_span mt-5">
                  <a href={`tel:${contact?.telTwo}`}>{contact?.telTwo}</a>
                </span>

                <h3>Çalışma Saatleri:</h3>
                <span className="contact_span">
                Pazartesi - Cuma: {contact?.workingHoursWeekdays}
                </span>
                <br></br>
                <span className="contact_span">
                Cumartesi - Pazar: {contact?.workingHoursWeekends}
                </span>

                <h3>Adres:</h3>
                <span className="contact_span ">
                {contact?.address}
                </span>
                <div className="pt-3">
                  <iframe
                    className="map_iframe"
                    src={contact?.googlemap}
                  ></iframe>
                </div>
                <h3 className="social_media_title">
                  Bizi Sosyal Medyadan Takip Edin :
                </h3>

                <div className="row">
                  <div className=" footer_icon">
                    <a
                      className="footer_social_media_icon"
                      href={contact?.x}
                      target="_blank"
                    >
                      <BsInstagram className="footer_icon_inner" />
                    </a>
                    <a
                      className="footer_social_media_icon"
                      href={contact?.facebook}
                      target="_blank"
                    >
                      {" "}
                      <FaFacebookF className="footer_icon_inner" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 mt-3">
              <div className="">
                <ContactLeft />
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0">
          <div className="container">
            <div className="col-lg-12 d-flex justify-content-center contact_footer_img ">
              <img src="/assets/images/footer/footer_restoran1.png" />
            </div>
          </div>
        </div>
      </main>
      <footer>
        <div className="row white_section m-0 ">
          <div className="col-lg-12 col-md-4">
            <div className="footer_logo d-flex flex-column justify-content-center align-items-center mt-5">
              <img src="/assets/images/logo/meyhane_footer.png" />
            </div>
          </div>
        </div>

        <div
          className="col-xl-10 offset-xl-1 container-fluid"
          style={{ position: "absolute", bottom: "0" }}
        >
          <CopyRight />
        </div>
      </footer>
    </Layout2>
  );
};

export default Contact;
