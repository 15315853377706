import React from "react";
import "../assets/css/components/Map/Map.css";
import Layout2 from "../components/Layout/Layout2";

const MapTarget = () => {
  return (
    <Layout2>
 <main className="map">
      <div className="row m-0">
        <div className="col-lg-12 d-flex align-items-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d541.0129955467322!2d32.76406550275892!3d39.90935517780294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3462b5d1fa0df%3A0xfebcd9b9961ab4a!2sKarafaki!5e0!3m2!1str!2str!4v1673267508639!5m2!1str!2str"
            width="100%"
            height="600"
           
          ></iframe>
        </div>
      </div>
    </main>
    </Layout2>
   
  );
};

export default MapTarget;
