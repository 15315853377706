import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../assets/css/components/Header/Header.css";
import zero from "../../assets/images/header/0.jpg";
import one from "../../assets/images/header/1.jpg";
import two from "../../assets/images/header/2.jpg";
import three from "../../assets/images/header/3.jpg";
import four from "../../assets/images/header/4.jpg";

const Header = () => {
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  const btnWrapper = useRef();
  useEffect(() => {
    let btn = document.getElementById("menuBtn");
    let left = document.querySelector(".left");
    let right = document.querySelector(".right");
    let headerContainer =
      document.getElementsByClassName("header-container")[0];

    let links = document.querySelectorAll(".right a");
    let backs = [zero, one, two, three, four];

    let flag = true;

    left.style.background = "../../assets/images/header/0.jpg";

    btn.addEventListener("click", () => {
      flag = !flag;
      if (!flag) {
        headerContainer.style.display = "flex";
        setTimeout(() => {
          left.style.transform = "translateY(0)";
          right.style.transform = "translateY(0)";
        }, 100);
      } else {
        left.style.transform = "translateY(100vh)";
        right.style.transform = "translateY(-100vh)";
        setTimeout(() => {
          headerContainer.style.display = "none";
        }, 400);
      }
      left.style.background = `url(${zero})`;
    });

    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener("mouseover", () => {
        left.style.background = `url(${backs[i]})`;

        for (let index = 0; index < links.length; index++) {
          links[index].style.opacity = 1;
          if (i !== index) {
            links[index].style.opacity = 0.5;
          }
        }
      });
    }
    btnWrapper.current.addEventListener("mouseout", () => {
      for (let index = 0; index < links.length; index++) {
        links[index].style.opacity = 1;
      }
    });
  }, []);

  return (
    <header>
      <div className="header-logo text-center">
        <Link to="/">
          <img
            src="/assets/images/logo/meyhanelogo.png"
            width="200px"
            alt="karafaki-meyhane"
          />
        </Link>
      </div>
      <div id="menuBtn" className="m-3">
        <input type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className="header-container">
      <div
          className="right d-flex align-items-start"
          data-animation="to-bottom"
        >
          <div className="align-items-center">
            <div className="wrapper-links mt-5 " ref={btnWrapper}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-link-active" : ""
                }
                data-text="ABOUT US"
                to="/"
              >
                <small>01.</small>
                <span>Anasayfa</span>
              </NavLink>
              <NavLink
                data-text="TOURS"
                to="/menu"
                className={({ isActive }) =>
                  isActive ? "nav-link-active" : ""
                }
              >
                <small>02.</small>
                <span>Menü</span>
              </NavLink>
              <NavLink
                data-text="CONTACT"
                to="/gallery"
                className={({ isActive }) =>
                  isActive ? "nav-link-active" : ""
                }
              >
                <small>03.</small>
                <span>Galeri</span>
              </NavLink>
              <NavLink
                data-text="CONTACT"
                to="/contact"
                className={({ isActive }) =>
                  isActive ? "nav-link-active" : ""
                }
              >
                <small>04.</small>
                <span>İletişim</span>
              </NavLink>
              <a
              target="_blank"
                data-text="EQUIPMENT"
                href="https://www.karafakigumusluk.com/"
                className={({ isActive }) =>
                  isActive ? "nav-link-active" : ""
                }
              >
                <small>05.</small>
                <span>Konseptlerimiz</span>
              </a>
            </div>
          </div>
        </div>
        <div
          className="left d-none d-md-block"
          style={{ background: "var(--blue)" }}
        ></div>
      </div>
    </header>
  );
};

export default Header;
