import React from "react";
import "../../assets/css/components/CopyRight/CopyRight.css"

const CopyRight = () => {
  return (
    <div>
      <div className="divider"></div>
      <i>
        <h6 className="copy-rights text-md-end text-center">
          &copy; 2023 All rights reserved.
          <a
            className="osi mx-2"
            href="https://osireklamajansi.com/"
            target="_blank"
          >
            <h6>OsiCrew.</h6>
          </a>
        </h6>
      </i>
    </div>
  );
};

export default CopyRight;
