import "./assets/css/style.css";
import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from "./routes/Contact";
import MapTarget from "./routes/MapTarget";

const Home = lazy(() => import("./routes/Home"));
const Gallery = lazy(() => import("./routes/Gallery"));
const MenuDetail = lazy(() => import("./routes/MenuDetail.jsx"));

const Loader = () => (
  <div style={{ height: "100vh", background: "var(--blue)", width: "100%" }}>
    <img
      src="/assets/gifs/Loadingmeyhane.gif"
      alt="karafaki-gumusluk"
      width="100%"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        maxWidth: 250,
      }}
    />
  </div>
);
function App() {
  console.log(1);
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route
          path="/gallery"
          element={
            <Suspense fallback={<Loader />}>
              <Gallery />
            </Suspense>
          }
        />
        <Route
          path="/menu"
          element={
            <Suspense fallback={<Loader />}>
              <MenuDetail />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/map" element={<MapTarget />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
