function Loader() {
    return (
      <div style={{ height: "100vh", background: "var(--blue)", width: "100%" }}>
        <img
          src="/assets/gifs/Loadingmeyhane.gif"
          alt="karafaki-meyhane"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: 250,
            maxWidth: "100%",
          }}
        />
      </div>
    );
  }
  
  export default Loader;
  