import { gql } from "@apollo/client";

export const GET_GALLERIES_BY_BRANCH = gql`
  query getGalleryByBranch($branch: String!) {
    getGalleryByBranch(branch: $branch) {
      branch
      alt
      name
    }
  }
`;

export const GET_MENU_PAGE = gql`
  query getMenuPage {
    getCategories {
      title
      img
      branch
    }

    getMenuPdfs {
      branch
      name
      lastEdit
    }
  }
`;

export const GET_CONTACT = gql`
  query getContactById($branch: String!) {
    getContactById(branch: $branch) {
      branch
      telOne
      telTwo
      workingHoursWeekdays
      workingHoursWeekends
      address
      googlemap
      facebook
      x
    }
  }
`;
