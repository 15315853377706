import React from "react";
import { useRef, useState } from "react";
import "../../assets/css/components/ContactLeft/ContactLeft.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";

const ContactLeft = () => {
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const encryptedText = CryptoJS.AES.encrypt(
      uuidv4(),
      process.env.REACT_APP_SSK
    ).toString();

    const FD = new FormData(form.current);
    FD.append("accessToken", encryptedText);
    const obj = {
      message: FD.get("message"),
      userInfo: FD.get("name") + " " + FD.get("surname"),
      from: FD.get("from"),
      subject: "Karafaki Meyhane websitesinden mesajınız var.",
      tel: FD.get("tel"),
      ownerName: "infokarafakigumusluk@gmail.com",
      to: "karafaki@karafakigumusluk.com",
      host: "smtp.gmail.com",
      service: "gmail",
      accessToken: encryptedText,
    };

    try {
      const res = await axios.post("https://nodemailer.3wweb.org/", obj, {
        auth: {
          username: "OSICrew_Node_Mailler",
          password: "1786oSi?173",
        },
      });
      if (res) {
        console.log(res);
        setIsSuccess(true);
        toast.success("Mesajınız gönderildi");
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    } catch (error) {
      console.log(error);
      toast.error("Mesajınız gönderilemedi!");
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  };

  return (
    <>
      <ToastContainer />

      <main className="contact_left">
        <h3 className="text-center"> Bizimle İletişime Geçin</h3>
        <form
          className="form"
          id="contactForm"
          name="sentMessage"
          onSubmit={onSubmitHandler}
          ref={form}
        >
          <div className="row m-0 bottom_row">
            <div className="col-lg-6 ">
              {" "}
              <div className="form-group">
                <label className="label-text" for="exampleFormControlSelect1">
                  Ad
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  id="name"
                  cols="2"
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 ">
              {" "}
              <div className="form-group">
                <label
                  // email="email"
                  className="label-text"
                  for="exampleFormControlSelect2"
                >
                  Soyad
                </label>
                <input
                  name="surname"
                  required
                  className="form-control"
                  cols="2"
                  id="surname"
                />
              </div>
            </div>
          </div>
          <div className="row m-0 bottom_row">
            <div className="col-lg-6 ">
              {" "}
              <div className="form-group">
                <label className="label-text" for="exampleFormControlSelect1">
                  Telefon
                </label>
                <input
                  name="tel"
                  type="number"
                  className="form-control"
                  id="tel"
                  cols="2"
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 ">
              {" "}
              <div className="form-group">
                <label
                  email="email"
                  className="label-text"
                  for="exampleFormControlSelect2"
                >
                  Email
                </label>
                <input
                  // name="email"
                  name="from"
                  required
                  className="form-control"
                  cols="2"
                  id="email"
                />
              </div>
            </div>
          </div>

          <div className="form-group display_grid">
            <label
              message="message"
              className="label-text"
              for="exampleFormControlTextarea1"
            >
              Mesaj
            </label>
            <textarea
              name="message"
              className="form-control-message"
              id="message"
              rows="4"
            ></textarea>
          </div>

          <div className="submit_button mt-5 d-flex justify-content-end">
            <button
              type="submit"
              class="btn btn-outline-dark "
              onSubmit={onSubmitHandler}
            >
              Gönder
            </button>
          </div>
        </form>
      </main>
    </>
  );
};

export default ContactLeft;
