import React, { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import "../../assets/css/components/Cookie/cookie.css";

function Cookie() {
  const [cl, setCl] = useState("d-none");

  const saveCookie = () => {
    Cookies.set("karafaki-gumusluk", true, { expires: 3 });
    setCl("d-none");
  };

  useEffect(() => {
    if (!Cookies.get("karafaki-gumusluk")) setCl("");
  }, []);

  return (
    <div className={"cookie " + cl}>
      <div className="first-part"></div>
      <div className="second-part">
        <div className="d-flex h-100 justify-content-center align-items-lg-center align-items-start flex-lg-row flex-column  container">
          <p className="cookie-text mt-3">
            Bu site sizlere daha iyi hizmet verebilmek için çerezleri
            kullanmaktadır. Çerezler analitik amaçlı ve kullanıcı performansını
            arttırmak için kullanılır.
          </p>
          <button className="btn btn-primary ms-2" onClick={saveCookie}>
            Çerezleri onaylıyorum.
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cookie;
